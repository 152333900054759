'use client'

import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Search } from 'lucide-react'
import { useRouter } from 'next/navigation'
import SearchForm from './SearchForm'
import SearchSuggestions from './SearchSuggestions'
import SearchHistory from './SearchHistory'

const SEARCH_HISTORY_KEY = 'searchHistories'
const MAX_SEARCH_HISTORY = 10

const HeaderSearch: React.FC = () => {
  const router = useRouter()

  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchHistories, setSearchHistories] = useState<string[]>([])
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isExpanded) {
      inputRef.current?.focus()
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }

    // Load search history
    const loadSearchHistory = async () => {
      const historiesString = localStorage.getItem(SEARCH_HISTORY_KEY)
      setSearchHistories(JSON.parse(historiesString || '[]'))
    }
    loadSearchHistory()

    // Clean up function
    return () => {
      document.body.style.overflow = ''
    }
  }, [isExpanded])

  const toggleSearch = useCallback(() => {
    setIsExpanded(!isExpanded)
    if (!isExpanded) {
      setSearchTerm('')
    }
  }, [isExpanded])

  const saveSearchHistory = useCallback(async (query: string) => {
    const oldHistories: string[] = JSON.parse(
      localStorage.getItem(SEARCH_HISTORY_KEY) || '[]',
    )
    const updatedHistories = [
      query,
      ...oldHistories.filter((h) => h !== query),
    ].slice(0, MAX_SEARCH_HISTORY)
    setSearchHistories(updatedHistories)
    localStorage.setItem(SEARCH_HISTORY_KEY, JSON.stringify(updatedHistories))
  }, [])

  const search = useCallback(
    (query: string) => {
      const trimmedQuery = query.trim()
      if (trimmedQuery === '') return

      setSearchTerm(trimmedQuery)
      saveSearchHistory(trimmedQuery)
      router.push(`/search?q=${encodeURIComponent(trimmedQuery)}`)
      toggleSearch()
    },
    [saveSearchHistory, router, toggleSearch],
  )

  return (
    <div className="relative flex items-center self-stretch text-sm">
      <button
        onClick={toggleSearch}
        className="px-4 py-2 text-gray-600 hover:text-gray-900 focus:outline-none"
      >
        <Search size={24} />
      </button>

      {isExpanded && (
        <div className="fixed inset-0 z-50 flex flex-col overflow-hidden bg-white">
          <SearchForm
            inputRef={inputRef}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            onSearch={search}
            onClickBack={toggleSearch}
          />

          <div className="flex-1 overflow-y-auto p-4">
            <SearchHistory
              searchHistories={searchHistories}
              setSearchHistories={setSearchHistories}
              onSearch={search}
            />
            <SearchSuggestions onSearch={search} />
          </div>
        </div>
      )}
    </div>
  )
}

export default HeaderSearch

import useSWR from 'swr'
import { get } from './axios'
import { PostDetail } from '@/types/api/post'

export type SearchType = 'post'

export const useSearchApi = () => {
  const searchApi = (q: string, type: SearchType, page: number) =>
    get<PostDetail[]>(`/search?q=${q}&type=${type}&page=${page}`)

  return { searchApi }
}

export const useSearchSuggestions = () => {
  return useSWR('/search/suggestions', async (url) => {
    try {
      const res = await get<string[]>(url)
      return res.data
    } catch (error) {
      console.error('Failed to fetch search suggestions', error)
      throw new Error('Failed to fetch search suggestions')
    }
  })
}

'use client'

import useAnalytics from '@/hooks/useAnalytics'
import { getOrCreateVisitorId } from '@/lib/identity'
import { useEffect } from 'react'

const CSInitHandler = () => {
  const { identify } = useAnalytics()

  useEffect(() => {
    if (identify) {
      const visitorId = getOrCreateVisitorId()
      identify(visitorId, { visitor_id: visitorId })
    }
  }, [identify])

  return null
}

export default CSInitHandler
